export const styles = {
  tableColumnText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#1A173B',
    width: '25%',
    paddingBottom: '0%',
  },
  collapseHeader: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '0.14px',
    color: '#1A173B',
    marginLeft: '0.3em',
  },
  collapseButton: {
    width: '1em',
    height: '1em',
    padding: '0',
    outline: 'none',
  },
  tableStyle: { marginTop: '25px', marginLeft: '2vw', width: '90%' },
  addNewButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  addNewButton: {
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '4px',
    padding: '0.5em',
    marginLeft: '1rem',
  },
  actionPillButton: {
    minHeight: '35px',
    maxHeight: '35px',
    minWidth: '24px',
    maxWidth: '24px',
    padding: '0px 0px 0px 0px',
    border: '2px solid #0077FF',
  },
  redWarningText: {
    color: 'red',
  },
  centerText: {
    textAlign: 'center',
  },
  inputWidth: {
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  circularProgress: {
    marginRight: '1rem',
  },
};
