// External imports
import React, { useEffect } from 'react';
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  NativeSelect,
  FormControl,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { ROLE_TYPE, HIERARCHY_TYPE } from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks.js';
import OfficeRadioList from '#/Auth/OfficeRadioList.jsx';
import { getHighLowLicensedSeasons } from '~/app/Utility/general';
// Styling imports
import { styles } from '~app/Components/Auth/css/switchOfficeYear.js';
import '~app/Components/Auth/css/switchOfficeYear.css';

/**
 * Handles displaying the Switch Office or Tax Year modal
 *
 * @Component SwitchOfficeYear
 */
const SwitchOfficeYear = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    efin: '',
    selectedEFINID: '',
    season: '',
    licenseInfo: [],
    byPassLicense: false,
  });

  useEffect(() => {
    if (
      [ROLE_TYPE.EFIN, ROLE_TYPE.PREPARER].includes(
        props.drilldownHistory[props.drilldownHistory?.length - 1].role,
      )
    ) {
      const licenseInfo = [...props.licenseInfo];
      const { highestLicensedSeason } = getHighLowLicensedSeasons(licenseInfo);

      setState({
        licenseInfo,
        season: highestLicensedSeason,
      });
    } else {
      // If current login is multi-office and greater, bypass license
      // Having issue with MUI disabled property on handling truthy/falsey condition if user is MO and greater
      setState({
        byPassLicense: true,
        licenseInfo: [{}],
        season: props.payload.season,
      });
    }

    let defaultEFIN;
    let selectedEFINID;

    // Grab list osf offices associated to a preparer
    // If for some reason this list of offices comes back as null, attempt to pull the office info via the preparer info
    if (
      (props.payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER ||
        props.payload.hierarchy_type_id === HIERARCHY_TYPE.EFIN) &&
      props.payload.offices
    ) {
      const currentOffice = props.payload.offices.find(office => office.is_active === true);
      defaultEFIN = currentOffice.efin;
      selectedEFINID = currentOffice.efin_id;
    } else if (
      props.payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER &&
      props.payload.preparer_info !== null
    ) {
      const currentOffice = props.payload.preparer_info.offices.find(
        office => office.efin_id === props.payload.preparer_info.active_efin_id,
      );
      defaultEFIN = currentOffice.efin;
      selectedEFINID = currentOffice.efin_id;
    }

    setState({
      efin: defaultEFIN,
      selectedEFINID,
    });
    return () => {};
  }, []);

  /**
   * Handles selecting an office
   *
   * @param {Object} e eventDOM used retrieving target value
   */
  const handleOfficeChange = e => {
    setState({ selectedEFINID: e });
  };

  /**
   * Handles changing tax year
   *
   * @param {string} season season for tax year
   */
  const handleSeasonChange = season => {
    setState({ season });
  };

  /**
   * doSwitch handles a request to switch the current tax year.
   * If list of offices is null for some reason, use the current EFINID state instead of looking for the selected dropdown value
   */
  const doSwitch = () => {
    if (
      props.payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER ||
      props.payload.hierarchy_type_id === HIERARCHY_TYPE.EFIN
    ) {
      if (props.payload.offices) {
        const offices = props.payload.offices;
        const selectedOffice = offices.find(
          office => office.efin_id === parseInt(state.selectedEFINID),
        );

        props.onClickContinue(selectedOffice.efin_id, state.season);
      } else {
        props.onClickContinue(state.selectedEFINID, state.season);
      }
    } else {
      props.onClickContinue(undefined, state.season);
    }
  };

  /**
   * Handles rendering the dropdown options
   *
   * @returns {HTML} tax year options ofr the dropdown
   */
  const setupTaxYearSelection = () => {
    const taxYearOptions = [];
    const currentSeason = 2025; // Change this value at end of tax year.
    const lastSupportedSeason = 2022; // The initial season CLO was release was TY2017 season 2018.
    const { highestLicensedSeason, lowestLicensedSeason } = getHighLowLicensedSeasons(
      state.licenseInfo,
    );
    let lowestSeason = lastSupportedSeason;
    let highestSeason = currentSeason;

    // Generate all supported seasons unless we are at the office level where we should rely on there highest and lowest licensed seasons
    if (
      [ROLE_TYPE.EFIN, ROLE_TYPE.PREPARER].includes(
        props.drilldownHistory[props.drilldownHistory?.length - 1].role,
      )
    ) {
      lowestSeason = lowestLicensedSeason;
      highestSeason = highestLicensedSeason;
    }

    for (let season = lowestSeason; season <= highestSeason; season++) {
      // Allows only QA accounts + superusers to test latest year in PROD
      // if (
      //   ENVIRONMENT === 'production' &&
      //   season === currentSeason &&
      //   !['NAHMIC', 'AMAPRI00', 'CLOTST'].includes(props.payload.account_code) &&
      //   !props.payload.is_super_user
      // ) {
      //   continue;
      // }

      // We want to make sure we only show TYs for our supported seasons.
      if (season >= lastSupportedSeason) {
        taxYearOptions.unshift(
          <option key={season} value={season}>
            {season - 1} Tax Year
          </option>,
        );
      }
    }

    return taxYearOptions;
  };

  return (
    <div className="switch-office-year-title-bar">
      <AppBar position="static">
        <Toolbar className={classes.toolbarColor}>
          <Typography className={classes.typographyStyle}>
            {props.officeSwitchModalActive ? 'Switch Office' : 'Switch Tax Year'}
          </Typography>
          <Button className={classes.closeButton} onClick={props.onClose}>
            X
          </Button>
        </Toolbar>
      </AppBar>
      <FormControl className={classes.modalBody}>
        {state.selectedEFINID && props.payload.offices && props.officeSwitchModalActive ? (
          <div>
            <span className={classes.headerLabels}>Selected Office</span>
            <OfficeRadioList
              defaultEFIN={state.selectedEFINID}
              onOfficeChange={e => handleOfficeChange(e)}
            />
            <hr />
          </div>
        ) : null}
        {props.taxyearSwitchModalActive && (
          <>
            <span className={classes.headerLabels}>Selected Year</span>
            <NativeSelect
              value={state.season}
              onChange={e => handleSeasonChange(e.target.value)}
              name="season"
              className={classes.ddl}
              disableUnderline={true}
              disabled={!state.licenseInfo.length}
            >
              {state.byPassLicense || state.licenseInfo.length ? (
                setupTaxYearSelection()
              ) : (
                <option value="none">
                  This Office has not yet been assigned a License. Contact your supervisor.
                </option>
              )}
            </NativeSelect>
          </>
        )}
        <div className={`container ${classes.buttonContainer}`}>
          <Button
            id="btnCancelSwtichAccount"
            size="small"
            color="primary"
            className={classes.cancelButton}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            id="btnDoSwtichAccount"
            size="small"
            color="primary"
            variant="contained"
            onClick={doSwitch}
            disabled={!props.officeSwitchModalActive ? !state.licenseInfo.length : false}
          >
            Switch
          </Button>
        </div>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(SwitchOfficeYear);
