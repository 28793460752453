// External Imports
import React, { Fragment, useEffect } from 'react';
import {
  Button,
  Grid,
  Input,
  InputLabel,
  FormControl,
  NativeSelect,
  Typography,
  CircularProgress,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
// Internal Imports
import { useSetState } from '~/app/Utility/customHooks.js';
import { VALID_EMAIL_REGEX } from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import InvoiceAPI from '~/app/api/remoteInvoiceAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
// Redux Imports
import { useSelector } from 'react-redux';
// Style Imports
import { taxReturnPaymentStyles } from '~/app/Pages/Payments/PaymentModal/css/taxReturnPayment.js';
import '~/app/Pages/Payments/PaymentModal/css/taxReturnPayment.css';

const styles = theme => taxReturnPaymentStyles(theme);

const RemoteInvoice = props => {
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;
  const { efin, taxpayerEmail, spouseEmail, officeProfile, tpFirstName, tpLastName } = useSelector(
    state => ({
      efin: state.officeProfile.efin,
      taxpayerEmail: state.formViewer.taxpayerEmailForPdf,
      spouseEmail: state.formViewer.spouseEmailForPdf,
      officeProfile: state.officeProfile,
      tpFirstName: props.returnPaymentInfo?.pname?.split(', ')?.[1],
      tpLastName: props.returnPaymentInfo?.pname?.substring(
        0,
        props.returnPaymentInfo?.pname?.indexOf(','),
      ),
    }),
  );
  const [state, setState] = useSetState({
    title: 'Tax Preparation Invoice' /* REQUIRED: name for the invoice */,
    returnGUID: '' /* REQUIRED */,
    walletToken: '' /* REQUIRED - wallet token */,
    secToken: '',
    efin /* REQUIRED: string, efin for the ERO tax preparer */,
    invoiceFees: [],
    emailAddress: '' /* required if sendInvoice is true - email address to send the invoice to */,
    customerMsg: '',
    emailType: 'other',
    firstNameOverride: tpFirstName,
    lastNameOverride: tpLastName,
    isLoading: true, // Loading screen
    submitted: false,
  });

  /**
   * Save return before proceding.
   * Also retrieve return guid and wallet token
   */
  useEffect(() => {
    getReturnGUID();
    getWalletToken(true);
  }, []);

  useEffect(() => {
    const invoiceFees = [];

    if (props.returnPaymentInfo?.efFee) {
      const efFee = formatFeeObject(props.returnPaymentInfo.efFee);
      invoiceFees.push(efFee);
    }

    if (props.returnPaymentInfo?.prepFee) {
      const prepFee = formatFeeObject(props.returnPaymentInfo.prepFee);
      invoiceFees.push(prepFee);
    }

    if (props.returnPaymentInfo?.techFee) {
      const techFee = formatFeeObject(props.returnPaymentInfo.techFee);
      invoiceFees.push(techFee);
    }

    setState({ invoiceFees });
  }, [props.returnPaymentInfo]);

  useEffect(() => {
    if (state.emailType === 'taxpayer') {
      setState({ emailAddress: taxpayerEmail });
    } else if (state.emailType === 'spouse') {
      setState({ emailAddress: spouseEmail });
    } else {
      setState({ emailAddress: '' });
    }
  }, [state.emailType]);

  const getReturnGUID = async () => {
    try {
      const res = await XlinkAPI.getReturnGUID(props.returnID);
      if (statusOK(res)) {
        const returnGUID = res.data;
        // strip hyphen
        const returnGUIDTrimmed = returnGUID.replaceAll('-', '');
        setState({ returnGUID: returnGUIDTrimmed });
      }
    } catch (error) {
      ErrorHelpers.handleError('Error fetching invoice data: ', error);
    }
  };

  const getWalletToken = async () => {
    const efinID = officeProfile?.efin_id;
    try {
      const response = await XlinkAPI.getWallet(efinID, props.returnID, true, true);
      if (response) {
        let token;
        let secToken;
        let walletType;
        // check if wallet type will be overridden
        // if true account will take care of all payments
        if (response.data?.walletAccountOverride) {
          walletType = 'account';
        } else {
          walletType = 'office';
        }

        // get the correct token for use
        const wallets = response.data?.wallets;
        wallets?.forEach(wallet => {
          if (wallet?.walletType === walletType) {
            token = wallet.token;
            secToken = wallet.secToken;
          }
        });
        setState({
          walletToken: token,
          secToken,
          isLoading: false,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to Fetch Wallet Information properly: ', error);
    }
  };

  const formatFeeObject = feeObject => {
    const formattedFee = {
      feeType: feeObject.FeeType,
      baseFee: feeObject.BaseFee ? feeObject.BaseFee / 100 : 0,
      adminFee: feeObject.AdminFee ? feeObject.AdminFee / 100 : 0,
      addonFee: feeObject.AddonFee ? feeObject.AddonFee / 100 : 0,
    };
    return formattedFee;
  };

  const createInvoice = async () => {
    setState({ submitted: true });
    if (!state.title || !state.emailAddress || !VALID_EMAIL_REGEX.test(state.emailAddress)) {
      return;
    }
    const invoice = {
      title: state.title,
      returnGUID: state.returnGUID,
      walletToken: state.walletToken,
      efin: state.efin.toString(),
      invoiceFees: state.invoiceFees,
      reqType: payload?.is_business ? 'i' : 'I',
      sendInvoice: false,
      emailAddress: state.emailAddress,
      customerMsg: state.customerMsg,
      secToken: state.secToken,
      firstNameOverride: state.firstNameOverride,
      lastNameOverride: state.lastNameOverride,
    };
    try {
      // Entire logic foregone at the moment
      // Now Remote Invoice will handle Sending the Invoice after creation
      // Keeping code here in case we switch back to sending ourselves

      // 1. Create Invoice
      // 2. Immediatley Get Invoice Data for display
      // 3. Open the Invoice Modal for review
      // const invoiceResp = await InvoiceAPI.createRemoteInvoice(invoice);
      // const invoiceData = await InvoiceAPI.getRemoteInvoice(
      //   invoiceResp?.data.urlGuid,
      //   state.walletToken,
      //   state.secToken,
      // );
      // dispatch(
      //   formViewerActions.setRemoteInvoice({
      //     ...invoiceData.data,
      //     customerMsg: state.customerMsg,
      //     walletToken: state.walletToken,
      //     secToken: state.secToken,
      //   }),
      // );
      // dispatch(formViewerActions.toggleRemoteInvoiceModal(true));
      // setState({ submitted: false });
      // props.onClose();

      // new protocal:
      // 1. Create Invoice
      // 2. Open New window to direct user to payjunction site
      // 3. After creation must archive invoice in db, can no longer do so after it is sent via RemoteInvoice Payjunction
      // 4. Inform Calcserver that a remote invoice was sent
      // 5. Close payment modal

      const invoiceResp = await InvoiceAPI.createRemoteInvoice(invoice, props.season);
      if (invoiceResp) {
        openRemoteInvoice(invoiceResp?.data.invoiceUrl);
        await XlinkAPI.insertRemoteInvoice(props.returnID, props.season, invoiceResp?.data.urlGuid);

        if (props?.sendRemotePayment) {
          props.sendRemotePayment();
        }
        props.onClose();
      }
    } catch (error) {
      return;
    }

    setState({ submitted: false });
  };

  const openRemoteInvoice = invoiceUrl => {
    const walletWindow = window.open();

    walletWindow.document.write(
      `<html>
          <head> </head>
          <body>
            Redirecting...
          </body>
    
        <!-- The following script will run and post right as the page loads -->
        <script type="text/javascript">
          // Generate our dynamic form
          const form = document.createElement("form");
          form.method = "POST";
          form.action =
          "${invoiceUrl}";
    
          // Add our security token to the form
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = "secToken";
          hiddenField.value = "${state.secToken}";
          form.appendChild(hiddenField);
    
          document.body.appendChild(form);
          form.submit();
        </script>
      </html>`,
    );
  };

  return state.isLoading ? (
    <Grid container spacing={8} direction="row" alignItems="center" justify="center">
      <Grid item xs={1}>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <Fragment>
      <Grid
        container
        spacing={8}
        alignContent="flex-start"
        justify="flex-start"
        alignItems="baseline"
      >
        <Grid item xs={8}>
          <FormControl fullWidth required>
            <InputLabel shrink>Title</InputLabel>
            <Input
              id="txtrcvdFrom"
              disableUnderline
              onChange={e => setState({ title: e.target.value })}
              placeholder="Name of Invoice"
              disabled={true}
              error={state.submitted && !state.title}
              value={state.title}
            />
            {state.submitted && !state.title ? (
              <FormHelperText id="titleHelper" error display={state.submitted && !state.title}>
                Required Field
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="txtEFIN" shrink>
              EFIN
            </InputLabel>
            <NumberFormat
              name="taxEFIN"
              id="txtEFIN"
              value={efin}
              customInput={Input}
              disableUnderline
              disabled={true}
              classes={{ root: classes.disabledInp }}
            />
          </FormControl>
        </Grid>

        {/* Business Remote Invoice does not use name fields */}
        {!payload?.is_business && (
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel shrink>Payer First Name</InputLabel>
              <Input
                id="firstName"
                disableUnderline
                onChange={e => setState({ firstNameOverride: e.target.value })}
                value={state.firstNameOverride}
              />
              {!state.firstNameOverride && state.submitted ? (
                <FormHelperText id="firstNameHelper" error display={state.submitted}>
                  Required Field
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        )}
        {!payload?.is_business && (
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel shrink>Payer Last Name</InputLabel>
              <Input
                id="lastName"
                disableUnderline
                onChange={e => setState({ lastNameOverride: e.target.value })}
                value={state.lastNameOverride}
              />
              {!state.lastNameOverride && state.submitted ? (
                <FormHelperText id="lastNameHelper" error display={state.submitted}>
                  Required Field
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        )}

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel shrink>Email Type</InputLabel>
            <NativeSelect
              disableUnderline
              id="selectemailtmethod"
              onChange={e => setState({ emailType: e.target.value })}
              placeholder="Email Address"
              label="Select an Email"
            >
              {taxpayerEmail ? (
                <option key="email1" value="taxpayer">
                  Taxpayer Email
                </option>
              ) : null}
              {spouseEmail ? (
                <option key="email2" value="spouse">
                  Spouse Email
                </option>
              ) : null}
              <option key="email3" value="other" selected>
                Other Email
              </option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth required>
            <InputLabel shrink>Email Address</InputLabel>
            <Input
              id="eAddress"
              disableUnderline
              onChange={e => setState({ emailAddress: e.target.value })}
              disabled={state.emailType !== 'other'}
              value={state.emailAddress}
              error={
                (!VALID_EMAIL_REGEX.test(state.emailAddress) || !state.emailAddress) &&
                state.submitted
              }
              classes={state.emailType !== 'other' ? { root: classes.disabledInp } : null}
            />
            {(!VALID_EMAIL_REGEX.test(state.emailAddress) || !state.emailAddress) &&
            state.submitted ? (
              <FormHelperText id="emailHelper" error display={state.submitted && !state.title}>
                {!state.emailAddress ? 'Required Field' : 'Improper Email Format'}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid classes={{ item: classes.manualPaymentButtonGrid }} item xs={12}>
          <Button
            id="btnManualPaymentCancel"
            classes={{ root: classes.payBtn }}
            onClick={props.onClose}
          >
            Close
          </Button>
          <Button id="btnManualPaymentSave" onClick={createInvoice}>
            Create Invoice
          </Button>
          <Typography variant="body1" color="error" gutterBottom></Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles)(RemoteInvoice);
