// External imports
import React from 'react';
import { Typography, Paper, Modal, withStyles } from '@material-ui/core';
// Image imports
import termsAndConditionsPDF from '~/public/documents/2025_TS_Online_Terms_of_Service_CrossLink_Online.pdf';
import textPrivacyPolicy from '~/public/documents/Privacy-Policy-CrossLink-FOC-1.25.2024.pdf';
import textTermsAndConditionsPDF from '~/public/documents/SMS-Messaging-Terms-1.1.2023.pdf';
// Styling imports
import { styles } from '~/app/Pages/TermsAndConditions/css/termsAndConditions.js';

const PreviewTermsAndConditions = props => {
  const { classes } = props;

  let pdf = termsAndConditionsPDF;
  if (props?.textlinkPDF === 'textPrivacyPolicy') {
    pdf = textPrivacyPolicy;
  } else if (props?.textlinkPDF === 'textTermsAndConditionsPDF') {
    pdf = textTermsAndConditionsPDF;
  }

  return (
    <Modal
      id="previewTermsAndCondtions"
      open={props.isPDFOpen}
      className={classes.modalSpacing}
      onClose={props.toggleModal}
    >
      <Paper className={classes.modal}>
        <Typography variant="body1" className={classes.modalTitle}>
          <span>Terms and Conditions</span>
          <span onClick={props.toggleModal} className="terms-and-conditions-cancel-btn">
            X
          </span>
        </Typography>

        <object
          className="terms-and-conditions-preview-pdf"
          id="termsAndConditionsPDF"
          data={pdf}
        />
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(PreviewTermsAndConditions);
