export const styles = {
  labelWidthStyle: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  fieldStyle: { width: '12vw', height: '3.5vh', marginRight: '3vw' },
  labelWidthStyleCheckbox: {
    height: '2vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '8px',
    marginTop: '0.8vh',
    marginBottom: '0.8vh',
    paddingLeft: '0px',
  },
  checkBoxBlank: { fontSize: 24, color: '#8FC3FF' },
};
