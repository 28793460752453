// External imports
import React, { Fragment, useEffect } from 'react';
import {
  Button,
  Fade,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { get } from 'lodash';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  buildMissingSignatureWarning,
  checkDBSignaturesExist,
  getDocumentList,
  findValueInObject,
  signatureStatus,
  doWeHaveSignatures,
  SIGNATURE_DOCUMENT_DESC,
} from './signatureListHelper.js';
import { CLIENT_REVIEW_TEXT, BANK_CONSENT_TEXT } from './BankingConsentDialogue.js';
import { remainingSignaturesNeeded } from '~/app/Pages/Returns/pages/Signatures/signaturesHelper.js';
import { SIGNATURE_LIST_TABLE_COLUMNS, SIGNEE } from '~/app/constants.js';
import missingIcon from '~/images/icons/missingIcon.png';
import pendingIcon from '~/images/icons/pendingIcon.png';
import signedIcon from '~/images/icons/signedIcon.png';
import InfoIcon from '@material-ui/icons/Info';
import WebHelpers from '~/app/webHelpers.js';
import { isSuperOrTechSupport } from '~/app/Utility/general.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
import { actions } from '~/app/redux/remoteSign/duck';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
// Styling imports
import { styles } from '~/app/Pages/Returns/pages/Signatures/components/css/signatureList.js';
import '~/app/Pages/Returns/pages/Signatures/components/css/signatureList.css';

/**
 * Component that displays documents needing signed as well as the status of the signature request.
 *
 * @component
 * @category Returns
 * @subcategory Signatures
 */
const SignatureList = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { AMENDED_RETURN, BANK, FINAL_RETURN, FNBA } = SIGNATURE_DOCUMENT_DESC;
  const payload = WebHelpers.getJWTPayload();

  const {
    signaturesNeeded,
    isBankAppAttached,
    isNeedingVerified,
    preparerID,
    amendedPreparerID,
    returnID,
    lockedStatus,
    currentSeason,
    remoteSignSuccessful,
    inOfficeSignSuccessful,
    isSelfPreparing,
    canEditReturn,
  } = useSelector(state => ({
    signaturesNeeded: state.remoteSign.signaturesNeeded,
    isBankAppAttached: state.remoteSign.isBankAppAttached,
    isNeedingVerified: state.formViewer.isNeedingVerified,
    preparerID: state.formViewer.preparerID,
    amendedPreparerID: state.formViewer.amendedPreparerID,
    returnID: state.returnProfile.returnID,
    lockedStatus: state.returnProfile.lockedStatus,
    currentSeason: state.returnProfile.year + 1, // taxyear + 1 = season
    remoteSignSuccessful: state.formViewer.remoteSignSuccessful,
    inOfficeSignSuccessful: state.formViewer.inOfficeSignSuccessful,
    isSelfPreparing: state.formViewer.isSelfPreparing,
    canEditReturn: Object.prototype.hasOwnProperty.call(
      loginSelector.getActiveAccessLevels(state),
      'edit_return',
    ),
  }));

  const [state, setState] = useSetState({
    isMissingEROSignatureModalOpen: false,
    isMissingPreparerSignatureModalOpen: false,
    hasEROSignature: false,
    hasPreparerSignature: false,
    taxReturnTime: '',
    bankTime: '',
    fnbaTime: '',
    taxReturnStatus: {},
    bankStatus: {},
    fnbaStatus: {},
    amendedReturnStatus: {},
    isUnlockMessageModalOpen: false,
    document: '',
    signee: '',
    signatureDocMap: new Map(),
    docsNeedingSigned: [],
    signaturesReq: [],
    openConsentModal: false,
    openClientReviewModal: false,
    bankConsentGiven: false,
    bankID: 1,
    printFinalBankDoc: false,
  });

  useEffect(() => {
    // Check to see if ERO, Preparer or Amended Preparer has an existing signature in the DB @return
    const PFBA = props.printPrefs?.PFBA?.configValue === '1';
    const PFBB = props.printPrefs?.PFBB?.configValue === '1';

    if (PFBA || PFBB) {
      setState({ printFinalBankDoc: true });
    }
  }, [props.printPrefs]);

  useEffect(() => {
    // Check to see if ERO, Preparer or Amended Preparer has an existing signature in the DB @return
    handleCheckDBSignaturesExist();
    handleTimeAndStatus();
  }, [
    remoteSignSuccessful,
    inOfficeSignSuccessful,
    preparerID,
    amendedPreparerID,
    signaturesNeeded,
  ]);

  useEffect(() => {
    if (props.bankID) {
      setState({ bankID: props.bankID });
    }
  }, [props.bankID]);

  useEffect(() => {
    // dependent data for signatures required to build view.
    documentsNeedingSigned();
  }, [state.docsNeedingSigned, state.bankStatus, state.taxReturnStatus, state.amendedReturnStatus]);

  /**
   * Fetches the time and the status from the DB of whether a document has been signed.
   */

  const handleTimeAndStatus = async () => {
    const documentList = await getDocumentList(returnID);
    if (documentList !== null) {
      const taxReturn = findValueInObject(documentList, '0000');
      const bank = findValueInObject(documentList, 'BANK');
      const fnba = findValueInObject(documentList, 'FNBA');
      const amendedReturn = findValueInObject(documentList, 'AMENDED RETURN');
      // create a map of signDocIDs if they exist
      const signdocMap = new Map();

      if (taxReturn) {
        signdocMap.set(FINAL_RETURN, taxReturn?.SignatureDocumentID || '');

        setState({
          taxReturnStatus: await signatureStatus(
            taxReturn.documentDescription,
            taxReturn.documentID,
          ),
          taxReturnTime: `${moment(taxReturn.createDate.substring(0, 10), 'YYYY-MM-DD').format(
            'MM/DD/YYYY',
          )} - ${moment(taxReturn.createDate.substring(11, 16), 'hh:mm').format('hh:mm A')}`,
        });
      }

      if (bank) {
        signdocMap.set(BANK, bank?.SignatureDocumentID || '');

        setState({
          bankStatus: await signatureStatus(bank.documentDescription, bank.documentID),
          bankTime: `${moment(bank.createDate.substring(0, 10), 'YYYY-MM-DD').format(
            'MM/DD/YYYY',
          )} - ${moment(bank.createDate.substring(11, 16), 'hh:mm').format('hh:mm A')}`,
        });
      }

      if (fnba) {
        signdocMap.set(FNBA, fnba?.SignatureDocumentID || '');

        setState({
          fnbaStatus: await signatureStatus(fnba.documentDescription, fnba.documentID),
          fnbaTime: `${moment(fnba.createDate.substring(0, 10), 'YYYY-MM-DD').format(
            'MM/DD/YYYY',
          )} - ${moment(fnba.createDate.substring(11, 16), 'hh:mm').format('hh:mm A')}`,
        });
      }

      if (amendedReturn) {
        signdocMap.set(AMENDED_RETURN, amendedReturn?.SignatureDocumentID || '');

        setState({
          amendedReturnStatus: await signatureStatus(
            amendedReturn.documentDescription,
            amendedReturn.documentID,
          ),
          amendedReturnTime: `${moment(
            amendedReturn.createDate.substring(0, 10),
            'YYYY-MM-DD',
          ).format('MM/DD/YYYY')} - ${moment(
            amendedReturn.createDate.substring(11, 16),
            'hh:mm',
          ).format('hh:mm A')}`,
        });
      }

      // Update the state of signatureDocMap
      setState({
        signatureDocMap: signdocMap,
      });
    }
  };

  /**
   * Checks which signatures we have stored in the DB for the ERO,
   * Preparer or the Amended Preparer.
   *
   * @function handleCheckDBSignaturesExist
   */
  const handleCheckDBSignaturesExist = async () => {
    const dbSignaturesExist = await checkDBSignaturesExist(
      preparerID,
      amendedPreparerID,
      returnID,
      isSelfPreparing,
    );

    const signaturesCollected = [];

    if (doWeHaveSignatures(dbSignaturesExist, 0)) {
      setState({ hasEROSignature: true });
    }

    // If this is a self prepared return, we do not require a preparer signature
    if (doWeHaveSignatures(dbSignaturesExist, 1) || isSelfPreparing) {
      setState({ hasPreparerSignature: true });
    } else {
      setState({ hasPreparerSignature: false });
    }

    // Setting to false matches the signatures needed redux state for skipping prep signature
    signaturesCollected[SIGNEE.PREPARER] = false;

    if (amendedPreparerID) {
      if (doWeHaveSignatures(dbSignaturesExist, 2)) {
        signaturesCollected[SIGNEE.AMENDED_PREPARER] = true;
        props.updateSignaturesCollected(SIGNEE.AMENDED_PREPARER, true);
      } else {
        props.updateSignaturesCollected(SIGNEE.AMENDED_PREPARER, false);
      }
    }

    docsNeedingSigned(signaturesCollected);
  };

  /**
   * Sets the documents that are needing signed.
   *
   * @param {Object[]} sigsCollected The signatures that we already have in the DB.
   */
  const docsNeedingSigned = sigsCollected => {
    const docsNeedingSigned = remainingSignaturesNeeded(signaturesNeeded, sigsCollected);
    setState({ docsNeedingSigned });
  };

  /**
   * Determines which image to display for the status of a document.
   *
   * @param {Object} signee The person signing the document.
   * @param {boolean} documentStatus The current status of whether a document has been signed.
   * @return {string} The status image.
   */
  const getIconImage = (signee, documentStatus) => {
    let image;
    // Display the missing icon if a signature request has never been initiated
    switch (get(signee, 'signatureStatus', documentStatus)) {
      case true:
        image = signedIcon;
        break;
      case false:
        image = pendingIcon;
        break;
      default:
        image = missingIcon;
    }
    return image;
  };

  /**
   * Gets the status icon for the given document type.
   *
   * @param {string} documentType The type of document.
   * @param {Object} finalSignee The signee on the final tax return.
   * @param {Object} bankSignee  The signee on the bank documents.
   * @param {Object} amendedSignee The signee on the amended return.
   */
  const getIcon = (documentType, finalSignee, bankSignee, amendedSignee, fnbaSignee) => {
    const { taxReturnStatus, bankStatus, amendedReturnStatus, fnbaStatus } = { ...state };
    let icon;
    if (documentType === FINAL_RETURN) icon = getIconImage(finalSignee, taxReturnStatus);
    else if (documentType === BANK) icon = getIconImage(bankSignee, bankStatus);
    else if (documentType === AMENDED_RETURN)
      icon = getIconImage(amendedSignee, amendedReturnStatus);
    else if (documentType === FNBA) icon = getIconImage(fnbaSignee, fnbaStatus);

    return icon;
  };

  /**
   * Displays the current status icon of the document.
   *
   * @param {string} signee The person who needs to sign the document.
   * @param {string} documentType The type of document needing signed.
   * @return {HTMLElement} The HTML element for the status icon.
   */
  const handleStatusIcon = (signee, documentType) => {
    const {
      taxpayer,
      spouse,
      amendedPreparer,
      officer,
      'taxpayer second': taxpayerSecond,
      'spouse second': spouseSecond,
    } = state.taxReturnStatus || {};
    const {
      taxpayer: taxpayerBank,
      spouse: spouseBank,
      amendedPreparer: amendedPreparerBank,
      'taxpayer second': taxpayerSecondBank,
      'spouse second': spouseSecondBank,
    } = state.bankStatus || {};
    const {
      taxpayer: taxpayerAmended,
      spouse: spouseAmended,
      amendedPreparer: amendedPreparerAmended,
      'taxpayer second': taxpayerSecondAmended,
      'spouse second': spouseSecondAmended,
    } = state.amendedReturnStatus || {};
    const {
      taxpayer: taxpayerFNBA,
      spouse: spouseFNBA,
      amendedPreparer: amendedPreparerFNBA,
      'taxpayer second': taxpayerSecondFNBA,
      'spouse second': spouseSecondFNBA,
    } = state.fnbaStatus || {};

    let icon;
    switch (signee) {
      case SIGNEE.TAXPAYER:
        icon = getIcon(documentType, taxpayer, taxpayerBank, taxpayerAmended, taxpayerFNBA);
        break;
      case SIGNEE.TAXPAYER_SECOND:
        icon = getIcon(
          documentType,
          taxpayerSecond,
          taxpayerSecondBank,
          taxpayerSecondAmended,
          taxpayerSecondFNBA,
        );
        break;
      case SIGNEE.SPOUSE:
        icon = getIcon(documentType, spouse, spouseBank, spouseAmended, spouseFNBA);
        break;
      case SIGNEE.SPOUSE_SECOND:
        icon = getIcon(
          documentType,
          spouseSecond,
          spouseSecondBank,
          spouseSecondAmended,
          spouseSecondFNBA,
        );
        break;
      case SIGNEE.AMENDED_PREPARER:
        icon = getIcon(
          documentType,
          amendedPreparer,
          amendedPreparerBank,
          amendedPreparerAmended,
          amendedPreparerFNBA,
        );
        break;
      case SIGNEE.OFFICER:
        icon = getIcon(documentType, officer, taxpayerBank, taxpayerAmended, taxpayerFNBA);
        break;
    }

    return <img className="signature-list-status-icon" src={icon} />;
  };

  /**
   * Handles displaying which documents need signed by each signee.
   *
   * @return {Object[]} The list of the current documents needing signed.
   */
  const documentsNeedingSigned = () => {
    const documentList = [];
    const bankAppList = [];
    const amendedReturnList = [];
    const isAmendedReturnAttached = get(signaturesNeeded, 'amended preparer', false);

    Object.keys(state.docsNeedingSigned).forEach(key => {
      documentList.push({
        document: FINAL_RETURN,
        time: state.taxReturnTime,
        signee: key,
        status: handleStatusIcon(key, FINAL_RETURN),
      });

      isBankAppAttached &&
        !state.printFinalBankDoc &&
        bankAppList.push({
          document: BANK,
          data: state.bankDate,
          time: state.bankTime,
          signee: key,
          status: handleStatusIcon(key, BANK),
        });

      isAmendedReturnAttached &&
        amendedReturnList.push({
          document: AMENDED_RETURN,
          time: state.amendedReturnTime,
          signee: key,
          status: handleStatusIcon(key, AMENDED_RETURN),
        });
    });

    setState({ signaturesReq: documentList.concat(bankAppList, amendedReturnList) });

    props.doneLoading();
  };

  /**
   * Handles the actions after the signed button is clicked.
   *
   * @param {string} signee The person signing the document.
   * @param {string} document The type of document needing signed.
   */
  const handleSignButton = (signee, document, bankConsentGiven = false) => {
    let pType = '3'; // Verify Final Tax Return
    let needsVerified = isNeedingVerified;

    if (document === AMENDED_RETURN) {
      pType = '18'; // Verify Amended Return - THIS MAY BE NEEDED IN THE FUTURE
      needsVerified = false;
    }

    if (document === BANK && !bankConsentGiven) {
      setState({ openClientReviewModal: true, signee, document });
      return;
    }

    if (state.hasEROSignature) {
      if (state.hasPreparerSignature) {
        dispatch(formViewerActions.signatureRequestActiveSet(true));
        // Display a message if the return is locked and user is trying to sign the final tax return
        if (
          lockedStatus &&
          document === SIGNATURE_DOCUMENT_DESC.FINAL_RETURN &&
          !state.isUnlockMessageModalOpen
        ) {
          setState({ isUnlockMessageModalOpen: true, signee, document });
        } else {
          props.clearRequestedSignatureData();
          // If a remote request was previously made, the signature doc id exists.
          const signatureDocID = state.signatureDocMap.get(document) || 0;
          props.getSignatureDocID(signatureDocID);
          // Setting the initial Signee Data to be used if there is a verfy error and navigates away from page
          dispatch(
            actions.initialSigneeDataSet({
              signee,
              document,
              signatureDocID,
            }),
          );

          needsVerified ? props.verifyReturn(pType) : props.openSendSignatureModal(pType);
        }
      } else {
        setState({ isMissingPreparerSignatureModalOpen: true });
      }
    } else {
      setState({ isMissingEROSignatureModalOpen: true });
    }
  };

  /**
   * Handles displaying the tooltip on the sign button.
   *
   * @return {string} The title of the tooltip.
   */
  const handleToolTipTitle = () => {
    let title = 'Send Remote Signature or Capture Signature';
    if (props.hasError) {
      title = 'Unable to proceed without signature data';
    } else if (!canEditReturn) {
      title =
        'Edit Return access level is required to send remote signatures or capture signatures';
    } else if (props.readyForReview && props.isFeederOffice) {
      title = 'Tax Return is currently under review and cannot be signed at the moment.';
    } else if (isSuperOrTechSupport(payload)) {
      title = 'Support users do not have access to this feature.';
    } else if (lockedStatus) {
      title = 'Return is locked and cannot be edited.';
    }
    return title;
  };

  const signClickHandler = row => {
    if (!props.returnSaved) {
      props.saveReturn();
    }

    let pType = '03'; // Final Tax Return

    if (row.document === AMENDED_RETURN) {
      pType = '18'; // Amended Return
    } else if (row.document === BANK) {
      pType = '04'; // Bank Document
    }

    props.handlePrint(pType, {}, ''); // return_print_query transaction

    handleSignButton(row.signee, row.document); // return_verify
  };

  // The location of the FNBA option was moved in season 2020
  const signeeTooltipMsg =
    currentSeason <= 2020
      ? "Taxpayer Bank Document and/or Spouse Bank Document signature is included from selecting 'Print Bank Documents with Final Return' in your 'Print Options' for Office Settings. The Taxpayer/Spouse Bank Document Signature is collected right after signing your Final Tax Return Signature."
      : "Taxpayer Bank Document and/or Spouse Bank Document signature is included from selecting 'Bank Application' in your '1040 Return Printing' for Office Settings. The Taxpayer/Spouse Bank Document Signature is collected right after signing your Final Tax Return Signature.";

  return (
    <Table>
      <TableHead>
        <TableRow>
          {SIGNATURE_LIST_TABLE_COLUMNS.map(column => {
            return (
              <TableCell key={column} classes={{ root: classes.cellHeader }}>
                {column}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {state.signaturesReq.map((row, i) => {
          const signeeDesc =
            row.signee === SIGNEE.TAXPAYER_SECOND
              ? 'TAXPAYER BANK DOCUMENT'
              : row.signee === SIGNEE.SPOUSE_SECOND
              ? 'SPOUSE BANK DOCUMENT'
              : row.signee.toUpperCase();
          return (
            <Fragment key={'fragment-' + i}>
              <TableRow key={'row-' + i} hover>
                <TableCell
                  variant="body"
                  classes={{ root: classes.cellRoot }}
                  component="th"
                  scope="row"
                >
                  {row.document}
                </TableCell>
                <TableCell
                  variant="body"
                  classes={{ root: classes.cellRoot }}
                  component="th"
                  scope="row"
                >
                  {row.time}
                </TableCell>
                <TableCell
                  variant="body"
                  classes={{ root: classes.cellRoot }}
                  component="th"
                  scope="row"
                >
                  {signeeDesc}
                  {[SIGNEE.TAXPAYER_SECOND, SIGNEE.SPOUSE_SECOND].includes(row.signee) && (
                    <Tooltip title={signeeTooltipMsg}>
                      <InfoIcon fontSize="small" classes={{ root: classes.cellTooltipIcon }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell
                  variant="body"
                  classes={{ root: classes.cellRootStatus }}
                  component="th"
                  scope="row"
                >
                  {row.status}
                </TableCell>
                <TableCell
                  variant="body"
                  classes={{ root: classes.cellRoot }}
                  component="th"
                  scope="row"
                >
                  {/* Taxpayer Second and Spouse Second will sign through TaxPayer & Spouse Information */}
                  {![SIGNEE.TAXPAYER_SECOND, SIGNEE.SPOUSE_SECOND].includes(row.signee) && (
                    <>
                      {/* Access Control can't wrap <Tooltip and vice versa. Just checking the 'edit_return' access level directly */}
                      <Tooltip
                        title={handleToolTipTitle()}
                        classes={{ tooltip: classes.tooltip }}
                        TransitionComponent={Fade}
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <div style={{ display: 'inline-flex', width: '70px' }}>
                          <Button
                            name={`${row.signee}signButton`}
                            id={`${row.signee}SignButton-${i}`}
                            size="small"
                            classes={{ root: classes.previewButton }}
                            onClick={() => signClickHandler(row)}
                            aria-owns={'signSignatureOptionMenu'}
                            disabled={
                              props.hasError ||
                              !canEditReturn ||
                              (props.readyForReview && props.isFeederOffice) ||
                              isSuperOrTechSupport(payload) ||
                              lockedStatus
                            }
                          >
                            Sign
                          </Button>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            </Fragment>
          );
        })}
      </TableBody>
      <SimpleDialog
        open={state.isUnlockMessageModalOpen}
        confirmText="Yes"
        cancelText="No"
        styled={true}
        onConfirm={() => {
          handleSignButton(state.signee, state.document);
          setState({ isUnlockMessageModalOpen: false });
        }}
        onClose={() => {
          setState({ isUnlockMessageModalOpen: false });
        }}
        dialogTitle="Return Locked"
        contentText="This return is currently locked. Would you like to proceed?"
      />
      <SimpleDialog
        open={state.isMissingEROSignatureModalOpen}
        onConfirm={() => {
          setState({ isMissingEROSignatureModalOpen: false });
        }}
        confirmText="OK"
        styled={true}
        dialogTitle="ERO Signature Missing"
        contentText={buildMissingSignatureWarning('ERO')}
      />
      <SimpleDialog
        open={state.isMissingPreparerSignatureModalOpen}
        onConfirm={() => {
          setState({ isMissingPreparerSignatureModalOpen: false });
        }}
        confirmText="OK"
        styled={true}
        dialogTitle="Preparer Signature Missing"
        contentText={buildMissingSignatureWarning('Preparer')}
      />
      <SimpleDialog
        open={state.openClientReviewModal}
        confirmText="Yes"
        cancelText="Cancel"
        styled={true}
        onConfirm={() => {
          setState({ openClientReviewModal: false, openConsentModal: true });
        }}
        onClose={() => {
          setState({ openClientReviewModal: false });
        }}
        dialogTitle={CLIENT_REVIEW_TEXT.Title}
        contentText={CLIENT_REVIEW_TEXT.Dialogue}
      />
      <SimpleDialog
        open={state.openConsentModal}
        confirmText="OK"
        cancelText="Cancel"
        styled={true}
        onConfirm={async () => {
          await setState({ openConsentModal: false });
          handleSignButton(state.signee, state.document, true);
        }}
        onClose={() => {
          setState({ openConsentModal: false });
        }}
        dialogTitle={BANK_CONSENT_TEXT[state.bankID].Title}
        contentText={BANK_CONSENT_TEXT[state.bankID].contentText}
        contentTextTwo={BANK_CONSENT_TEXT[state.bankID].contentTextTwo}
        contentTextThree={BANK_CONSENT_TEXT[state.bankID].contentTextThree}
      />
    </Table>
  );
};

export default withStyles(styles)(SignatureList);
