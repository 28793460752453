// External imports
import React, { useEffect } from 'react';
import { Button, Tooltip, List, ListItem, ListItemText, Grid, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
// Internal imports
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import {
  toggleRestrictedField,
  fieldID,
} from '~app/Pages/Setup/RestrictedFields/helpers/restrictedFieldsHelpers.js';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import '~/app/Pages/Returns/components/FieldInfo/css/fieldInfo.css';
import { styles } from '~/app/Pages/Returns/components/FieldInfo/css/fieldInfo.js';

const FieldInfo = props => {
  const { classes } = props;
  const { cobrand } = useSelector(state => ({
    cobrand: state.cobrand,
  }));
  const [state, setState] = useSetState({
    hideDrawer: 'hide',
    isCurrentFieldRestricted: null,
    isButtonDisabled: false,
    supportNumber: '',
    disableOverflow: true,
  });

  const isWorksheetDisabled = () => {
    if (!props.currentEl) {
      return true; // If there is no current element, the worksheet is disabled.
    }
    const hasStmCode = props.currentEl.getAttribute('stmcode');
    // If the field is already on a worksheet, disable the button
    // worksheet forms will have a length of 7 Ex: INCZ01a
    const isShortActiveForm = props.activeForm.length < 7;
    // On rare cases a worksheet will be able to open another worksheet, manually enable the button
    const isWksButtonEnabled = props.currentEl.classList?.contains('manual_enable_wks_btn');
    // If field has 'field_wks' attribute always have worksheet button on
    const hasWksField = props.currentEl.classList?.contains('field_wks');

    // Disable the worksheet if stmcode is missing or activeForm is too long, and it's not manually enabled.
    return !((hasStmCode && isShortActiveForm) || hasWksField) && !isWksButtonEnabled;
  };

  useEffect(() => {
    let supportNumber = '(833) 783-4253';
    if (cobrand && cobrand?.techSupportNumber) {
      supportNumber = cobrand?.techSupportNumber;
    }
    setState({ supportNumber });
  }, []);

  /**
   * @param {Object} restrictedFieldData is an object that contains updated field data sent by calcserver
   * Set within <Formviewer /> and sent into <FieldInfo /> as a prop, needed to trigger a rerender for <FieldInfo />
   */
  useEffect(() => {
    getCurrentRestrictedField();
    disableButton();
  }, [props.restrictedFieldData]);

  useEffect(() => {
    /**
     * A closure function for handling window screen resize to hide
     * the Field Info tab on the right hand side.
     */
    function handleResize() {
      if (window.outerWidth <= 960) {
        hideDrawer();
      }
    }
    window.addEventListener('resize', handleResize);

    // only fetch form links if the drawer is currently shown
    if (state.hideDrawer !== 'hide') {
      getFieldFormLinks();
    }
    // needs to be called when focusing to a different field
    getCurrentRestrictedField();
    disableButton();
  }, [props.currentEl]);

  /**
   * @param {Function} getCurrentRestrictedField is a function that checks if the currentField is currently locked,
   * if so, sets a boolean to true
   * @param {boolean} isCurrentFieldRestricted is a boolean that sets the status depending on if the currentField is locked
   */
  const getCurrentRestrictedField = () => {
    if (props.currentEl) {
      setState({
        isCurrentFieldRestricted: props.currentEl?.getAttribute('restricted') === 'true' ? 1 : 0,
      });
    }
  };

  /**
   * @param {Function} disableButton is a function that will disable the lock/unlock field button depending on
   * if it is a read-only field, a field on a locked return, or if there's no current Element to target
   * @param {boolean} readOnly is a boolean that checks if the current element has a 'read-only' class
   * @param {boolean} lockedField is a boolean that checks if the current element has a 'lockedField' class
   * @param {boolean} isButtonDisabled is a boolean that disables/enables the lock/unlock field button
   */
  const disableButton = () => {
    if (props.currentEl) {
      const readOnly = props.currentEl?.classList?.contains('read-only');
      const lockedField = props.currentEl?.classList?.contains('lockedField');
      setState({
        isButtonDisabled: readOnly || lockedField,
        disableOverflow: props.currentEl ? !props.currentEl.className.includes('field_ovl') : true,
      });
    } else {
      setState({
        isButtonDisabled: true,
      });
    }
  };

  /**
   * With the current field, we grab the field description from the field
   * alt property or title
   */
  const fieldDescription = () => {
    if (props.currentEl) {
      if (props.currentEl.getAttribute('title') || props.currentEl.getAttribute('alt')) {
        let fldDesc = props.currentEl.getAttribute('title') || props.currentEl.getAttribute('alt');
        if (props.taxYear) {
          // This is needed for seasons 2022 and prior. Xfm-conv did not handle these for "cellItems" causing some misses.
          // Xfm-conv has been patched and the logic below can be removed in 2027, since we support 4 years prior...
          if (fldDesc.includes('~XL~')) {
            fldDesc = fldDesc.replaceAll('~XL~', props.taxYear + 1);
          }
          if (fldDesc.includes('~TX~')) {
            fldDesc = fldDesc.replaceAll('~TX~', props.taxYear);
          }
          if (fldDesc.includes('~PR~')) {
            fldDesc = fldDesc.replaceAll('~PR~', props.taxYear - 1);
          }
          if (fldDesc.includes('~P2~')) {
            fldDesc = fldDesc.replaceAll('~P2~', props.taxYear - 2);
          }
          if (fldDesc.includes('~P3~')) {
            fldDesc = fldDesc.replaceAll('~P3~', props.taxYear - 3);
          }
          if (fldDesc.includes('~P4~')) {
            fldDesc = fldDesc.replaceAll('~P4~', props.taxYear - 4);
          }
        }
        return fldDesc;
      }
    }
    return 'No field description available';
  };

  //  getFieldFormLinks sends a calcserver command to fetch form links for a field
  const getFieldFormLinks = () => {
    if (props.activeForm && props.currentEl) {
      // The field input needs to have the "linkage" attribute before we fetch the field
      // form links.
      if (props.currentEl.id && props.currentEl.getAttribute('linkage')) {
        // Make a WS to calc if the field has any form links.
        props.calcAPI.ReturnFieldLinkage(props.activeForm + props.currentEl.id.substring(0, 4));
      }
    }
  };

  const setupFieldFormLinks = () => {
    if (
      props.fieldFormLinks &&
      Array.isArray(props.fieldFormLinks.linkageList) &&
      props.fieldFormLinks.linkageList.length
    ) {
      const formLinkslist = props.fieldFormLinks.linkageList.map((linkItem, index) => (
        <ListItem
          key={`${index}-${linkItem.listPick}`}
          classes={{ root: classes.formLinkListItem }}
          data-form-name={linkItem.formName}
          data-form-occur={linkItem.formOccur}
          data-list-pick={linkItem.listPick}
          data-short-desc={linkItem.shortDesc}
          data-long-desc={linkItem.longDesc}
          alt={linkItem.shortDesc}
          disableGutters={true}
          onClick={() => handleFormLinkClick(linkItem)}
        >
          <ListItemText
            classes={{
              primary: classes.formLinkListItemTextColor,
              secondary: classes.formLinkListItemTextSecondary,
            }}
            primary={`${linkItem.formName} : ${linkItem.longDesc}`}
            secondary={linkItem.shortDesc}
          />
        </ListItem>
      ));

      return <List classes={{ root: classes.formLinkList }}>{formLinkslist}</List>;
    } else {
      return <></>;
    }
  };

  const handleFormLinkClick = linkItem => {
    props.startSpin();

    let tors = '';
    const formOccur = linkItem.listPick.substring(0, 6);

    // Do we have TORS value (taxpayer (T) == 01, spouse (S) == 02)
    if (linkItem.listPick.charAt(6) === 'T') {
      tors = '01';
    } else if (linkItem.listPick.charAt(6) === 'S') {
      tors = '02';
    }

    props.calcAPI.ReturnAddForm(formOccur, tors);
    props.calcAPI.ReturnFormLoad(formOccur, '', '', '');
  };

  const loadWorksheet = (e, wksType) => {
    e.preventDefault();

    // Once the overflow is loaded, the active form in no longer the parent form, unlike a worksheet
    if (props.activeForm.slice(0, 3) === 'ovl') {
      return;
    }

    const form = props.activeForm + props.currentEl.getAttribute('stmcode');
    props.getTaxForm(form, props.currentEl.id, wksType);

    setState({ disableOverflow: true });
  };

  const handleDrawer = e => {
    e.preventDefault();

    if (!state.hideDrawer) {
      hideDrawer();
    } else {
      showDrawer();
    }
  };

  const hideDrawer = () => {
    setState({ hideDrawer: 'hide' });
  };

  const showDrawer = () => {
    getFieldFormLinks();
    setState({ hideDrawer: '' });
  };

  /**
   * @param {Function} handleToggle is a function that builds an updated restricted field row to send to XlinkAPI and calcAPI
   * @param {string} formOccur is a string of the current active form + occurence ex: US0101
   * @param {string} formID is a string that contains only the form key ex: US01
   * @param {string} field is an 8 character string that contains the form + field without occurence ex: US0101FLST
   * @param {description} description is a string that contains the field's description ex: 'Filing Status'
   *
   * @param {Object} row is an object that stores the field built with the previous params
   */
  const handleToggle = () => {
    const formOccur = props.activeForm;
    const formID = formOccur.slice(0, 4);
    const field = fieldID(formOccur, props.currentEl);
    const description = fieldDescription();

    const row = {
      field,
      description,
      formID,
      formTitle: '',
    };

    if (props.currentEl) {
      row.status = state.isCurrentFieldRestricted === 0 ? 1 : 0;
    }

    toggleRestrictedField(row, formOccur, props.calcAPI);
  };

  return (
    <div className="field-info-tab-container">
      <Button classes={{ root: classes.fieldInfoBtn }} onClick={e => handleDrawer(e)}>
        <Tooltip title="Click here to toggle the description, form links, or worksheets for the current field.">
          <InfoIcon classes={{ root: classes.fieldInfoBtnIcon }} />
        </Tooltip>
      </Button>
      <div
        className={`${props.isWizard ? 'field-info-drawer-estimator' : 'field-info-drawer'} ${
          state.hideDrawer
        }`}
      >
        <section className="field-info-description-container field-info-body-container">
          <h3 className="field-info-heading">Field Description</h3>
          {fieldDescription()}
        </section>

        <>
          <section className="field-info-fieldID-container field-info-body-container">
            <h3 className="field-info-heading">Field ID</h3>
            {fieldID(props.activeForm, props.currentEl)}
          </section>
          <div className="field-info-worksheet-container">
            <AccessControl accessLevel="create/edit_restricted_fields">
              <Button
                onClick={handleToggle}
                classes={{ root: classes.worksheetBtn }}
                disabled={state.isButtonDisabled}
              >
                {state.isCurrentFieldRestricted ? 'Unlock Field' : 'Lock Field'}
              </Button>
            </AccessControl>
          </div>
        </>

        <h3 className="field-info-heading">Form Links</h3>
        <section className="field-info-form-links-container field-info-body-container">
          <div className="field-info-form-links-content">{setupFieldFormLinks()}</div>
        </section>
        <section className="field-info-help-container">
          <div className="field-info-worksheet-container">
            <Button
              classes={{ root: classes.worksheetBtn }}
              onClick={e => loadWorksheet(e, 'wks')}
              disabled={isWorksheetDisabled()}
            >
              Worksheet
            </Button>
            <Button
              classes={{ root: classes.overflowBtn }}
              onClick={e => loadWorksheet(e, 'ovl')}
              disabled={state.disableOverflow}
            >
              Overflow sheet
            </Button>
          </div>
          <h3 className="field-info-heading">Customer Support</h3>
          <Grid container>
            <Grid item xs={8}>
              For technical Support:{' '}
              <span className="field-info-help-number">{state.supportNumber}</span>.
            </Grid>
          </Grid>
        </section>
      </div>
    </div>
  );
};

export default withStyles(styles)(FieldInfo);
