// External import
import React from 'react';
import moment from 'moment';
// Internal import
import HeaderMessage from '~/app/Components/Common/HeaderMessage/HeaderMessage.jsx';

const MaintenanceRoutineMsg = () => {
  const sundayDate = moment().day(7 + 0);
  const formatMsgEndDate = sundayDate.format('YYYY-MM-DD 20:30:00');

  const msg = `Business Modernized e-File (MeF) Production Shutdown. The IRS Business MeF Production Shutdown will begin on Thursday, December 26, 2024, at 11:59 a.m. Eastern Time. To ensure all returns are processed on time, we will continue to accept tax returns until 6:00 am Pacific Time (9:00 am Eastern Time) on December 26, 2024.`;

  return <HeaderMessage heading="Scheduled Maintenance" message={msg} endDate={formatMsgEndDate} />;
};

export default MaintenanceRoutineMsg;
